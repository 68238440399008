import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogPostsByCategory = (params = {}, options = {}) => {
  // console.log("!", params);

  const { category, ...shortParams } = params;

  // console.log(category, shortParams);

  return useQuery({
    queryFn: () => BlogService.getBlogPostsByCategory(params),
    queryKey: [
      "blog_posts_by_category",
      {
        category: category,
        pageNo: shortParams.pageNo ? shortParams.pageNo : shortParams,
      },
    ],
    enabled: typeof params !== typeof undefined,
  });
};
