import { SectionTopPostsByAuthorLazy } from "components/sections";
import { SEO } from "components/utils/Analytics";
import { Spinner } from "components/utils/Spinner";
import { PageSection } from "core/components";
import { dataMessages, dataTherapistsPage, dataTopPostsByAuthor } from "data";
import { Fragment, useEffect, useMemo } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { SectionTherapistPrices } from "./components";
import {
  TherapistBio,
  TherapistInfo,
  TherapistInfoCard,
  TherapistQualification,
  TherapistWorkingWith,
} from "./components/Details";
import { useGetTherapistBySlug } from "./utils/useGetTherapistBySlug.hook";

// Import Swiper styles
import { APP_API_URI } from "config/config";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// NOTE : https://epicbootstrap.com/snippets/team-cards

// Галина Лоянич - Разное, Психологи, Москва и Московская область на Яндекс Услуги
// Meta Title
// Галина Лоянич - Разное, Психологи, стоимость услуг от 3000 ₽.
// Meta Description
// https://uslugi.yandex.ru/profile/GalinaLoyanich-2728752
// Canonical URL

// website
// og:type
// Яндекс Услуги — поиск услуг и специалистов
// og:title
// Яндекс Услуги помогут сравнить цены, найти подходящего исполнителя и связаться с ним удобным способом — по телефону или во встроенном чате.
// og:description
// https://avatars.mds.yandex.net:443/get-ydo/4397410/2a0000018f1b7617120f916a1bf66701ad83/320x320
// og:image
// Missing
// og:url

// summary
// twitter:card
// Missing
// twitter:title
// Missing
// twitter:description
// https://avatars.mds.yandex.net:443/get-ydo/4397410/2a0000018f1b7617120f916a1bf66701ad83/320x320
// twitter:image
// Missing
// twitter:url
// Missing
// twitter:site

// @context: https://schema.org
// @type: LocalBusiness
// areaServed:
// Москва
// description: Я - семейный психолог. Помогаю своим клиентам справляться с различными психологическими проблемами с помощью семейного системного подхода и эмоционально-образной…
// image: https://avatars.mds.yandex.net:443/get-ydo/4397410/2a0000018f1b7617120f916a1bf66701ad83/face_mini
// name: Галина Лоянич
// priceRange: от 3000 ₽
// url: https://yandex.ru/uslugi/profile/GalinaLoyanich-2728752

const dataSections = [
  {
    title: "Профиль",
    subtitle: "Профиль",
    description: "Профиль",
  },
  {
    title: "С чем поможет",
    subtitle: "С чем поможет",
    description: "С чем поможет",
  },
  {
    title: "О себе",
    subtitle: "О себе",
    description: "",
  },
  {
    title: "Квалификация",
    subtitle: "Квалификация",
    description: "Квалификация",
  },
  {
    title: "Стоимость консультации психолога",
    subtitle: "Стоимость консультации психолога",
    description: "Стоимость консультации психолога",
  },
];

const SectionContent = (props) => {
  const { data } = props;
  const { therapist, subsections } = data;

  const TherapistDetails = () => {
    return (
      <div className="therapist-info-details">
        <TherapistInfo data={{ therapist, title: subsections[0].title }} />
        {/* <TherapistProfile data={{ therapist, title: subsections[0].title }} /> */}
        <TherapistWorkingWith
          data={{ therapist, title: subsections[1].title }}
        />
        <TherapistBio data={{ therapist, title: subsections[2].title }} />
        <TherapistQualification
          data={{ therapist, title: subsections[3].title }}
        />
        {/* <TherapistPrice data={{ therapist, title: subsections[4].title }} /> */}

        {/* <button onClick={() => navigate(-1)}>Go back</button>*/}

        {/* <DemoSection /> */}
      </div>
    );
  };

  return (
    <Row>
      <div className="col-lg-4 col-md-12 col-12">
        <TherapistInfoCard data={therapist} />
      </div>

      <div className="col-lg-8 col-md-12 col-12">
        <TherapistDetails />
      </div>
    </Row>
  );
};

/**
 * Component for showing section "Therapist" of the site.
 *
 * @component
 **/
const TherapistSection = (props) => {
  const { data, id = "therapist", cn = "pt-100 pb-100" } = props;
  const { slug } = useParams();
  const meta = dataTherapistsPage.meta;

  const {
    data: therapist,
    isLoading: isLoadingTherapist,
    isError: isErrorTherapist,
    error: errorTherapist,
  } = useGetTherapistBySlug(slug);

  const isLoading = isLoadingTherapist;
  const isError = isErrorTherapist;
  const errors = [errorTherapist];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (therapist) {
      _debug("THERAPIST:", therapist);
    }
  }, [therapist]);

  const newMeta = useMemo(() => {
    console.log("Therapist:", therapist);
    console.log("Meta:", meta);

    if (!therapist) return meta;

    const getImageURL = (slug) => {
      return `${APP_API_URI}/psychologists/psychologist/profile/${slug}/image`;
    };

    // Галина Лоянич - Разное, Психологи, Москва и Московская область на Яндекс Услуги
    const dataPageTitle = `${therapist?.name} - Разное, Психологи, Москва и Московская область на PsyWealth`;
    // Галина Лоянич - Разное, Психологи, стоимость услуг от 3000 ₽.
    const dataPageDescription = `${therapist?.name} - Разное, Психологи, стоимость услуг от 3000 ₽`;

    const dataMetaOGTitle = dataPageTitle;
    const dataMetaOGDescription = dataPageDescription;
    const dataMetaOGImage = getImageURL(slug);
    const dataMetaOGImageAlt = `${therapist?.qualification}`;

    const dataMetaTWImage = dataMetaOGImage;

    return {
      ...meta,
      ...{
        title: dataPageTitle,
        description: dataPageDescription,
        OG: [
          {
            property: "og:title",
            content: dataMetaOGTitle,
          },
          {
            property: "og:description",
            content: dataMetaOGDescription,
          },
          {
            property: "og:url",
            content: "",
          },
          {
            property: "og:image",
            content: dataMetaOGImage,
          },
          {
            property: "og:image:alt",
            content: dataMetaOGImageAlt,
          },
        ],
        TW: [
          {
            name: "twitter:title",
            content: dataPageTitle,
          },
          {
            name: "twitter:description",
            content: dataPageDescription,
          },
          {
            name: "twitter:image:src",
            content: dataMetaTWImage,
          },
        ],
      },
    };
  }, [therapist, meta, slug]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isErrorTherapist && errorTherapist?.status === 204) {
    return <div className="error">Нет данных о терапевте</div>;
  }

  /*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/

  // const newMeta = getMetaFromTherapist({
  //   meta: dataTherapistsPage.meta,
  //   therapist,
  // });
  console.log(newMeta);

  return (
    <Fragment>
      <SEO data={newMeta}>
        <link
          rel="canonical"
          href={`https://psywealth.com/therapists/${slug}`}
        />
      </SEO>
      <PageSection id={id} cn={cn}>
        {/* <div className="container">
        <SectionHeader data={data} showSubtitle showDescription />*/}
        <div className="container">
          <SectionContent data={{ therapist, subsections: dataSections }} />
        </div>
      </PageSection>
      <SectionTherapistPrices data={{ ...data, therapist }} />
      <SectionTopPostsByAuthorLazy
        data={dataTopPostsByAuthor}
        type={"style2"}
      />
    </Fragment>
  );
};

export default TherapistSection;
