import { BLOG_API_KEY, BLOG_API_URI, BLOG_ID } from "config/config";
import { _debug } from "utils";
import { getGlobalAuthHeader, requestGlobalOptions } from "./auth.service";
import { getResultOrError } from "./course.service";

const SERVICE_DEBUG = true;

const SERVICE_URL = `${BLOG_API_URI}/posts`;

const getServiceURL = (URL) =>
  URL ? `${SERVICE_URL}/${URL}` : `${SERVICE_URL}`;

const LOGGER = (message, obj, type) => {
  if (SERVICE_DEBUG) _debug(message, obj, type);
};

export const getAll = async () => {
  const URL = `${BLOG_API_URI}/posts/all?blog=${BLOG_ID}`;

  LOGGER("Get all blog posts by Blog Id", { BLOG_ID, URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  console.log("111", requestOptions);

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogPosts = async (params = {}) => {
  const URL = `${BLOG_API_URI}/posts`;
  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  LOGGER("Get blog posts by Blog Id and params", {
    BLOG_ID,
    queryString: queryString ? `${URL}?${queryString}` : URL,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  const response = await fetch(
    queryString ? `${URL}?${queryString}` : URL,
    requestOptions
  );
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogPostsByCategory = async (params = {}) => {
  const URL = `${BLOG_API_URI}/posts/category/${params.category}`;
  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  LOGGER("Get blog posts by Blog Id and category", {
    BLOG_ID,
    queryString: queryString ? `${URL}?${queryString}` : URL,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  const response = await fetch(
    queryString ? `${URL}?${queryString}` : URL,
    requestOptions
  );
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getSingleBlogPostBySlug = async (slug) => {
  const URL = getServiceURL(`v2/${slug}?blog=${BLOG_ID}`);

  LOGGER("Get Single Post by Slug", { slug, URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogPostMetaBySlug = async (slug) => {
  const URL = getServiceURL(`meta/post/slug/${slug}?blog=${BLOG_ID}`);

  LOGGER("Get Single Post (Metadata) by Id", { slug, URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getRelatedBlogPosts = async (params = {}) => {
  const URL = getServiceURL(`top?blog=${BLOG_ID}`);

  LOGGER("Get related blog posts by Blog Id and slug", {
    BLOG_ID,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogCategories = async () => {
  const URL = `${BLOG_API_URI}/categories/all?blog=${BLOG_ID}`;

  LOGGER("Get blog categories by Blog Id", { BLOG_ID, URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogCategory = async (params) => {
  const URL = `${BLOG_API_URI}/categories/slug/${params.slug}?blog=${BLOG_ID}`;

  LOGGER("Get blog category by Slug and Blog Id", {
    slug: params.slug,
    BLOG_ID,
    URL,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogTags = async (slug) => {
  const URL = `${BLOG_API_URI}/tags/all?blog=${BLOG_ID}`;

  LOGGER("Get blog tags by Blog Id", { BLOG_ID, URL });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogTag = async (params) => {
  const URL = `${BLOG_API_URI}/tags/slug/${params.slug}?blog=${BLOG_ID}`;

  LOGGER("Get blog tag by Slug and Blog Id", {
    slug: params.slug,
    BLOG_ID,
    URL,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  // console.log(requestOptions, JSON.stringify(data));

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogTopPosts = async (params = {}) => {
  const URL = getServiceURL(`top?blog=${BLOG_ID}`);

  LOGGER("Get top 3 blog posts by Blog Id", {
    BLOG_ID,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Api-Key": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  console.log("111", requestOptions, requestHeaders, BLOG_API_KEY);

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getBlogTopPostsByAuthor = async (author) => {
  const URL = getServiceURL(`top?author=${author}&blog=${BLOG_ID}`);

  LOGGER("Get top 3 blog posts by Blog Id and Author", {
    BLOG_ID,
    author,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  const response = await fetch(URL, requestOptions);
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

export const getSearchByTitle = async (params = {}) => {
  console.log("!!!!", params);
  const URL = `${BLOG_API_URI}/posts/v2`;
  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  LOGGER("Get blog posts by Blog Id and params", {
    BLOG_ID,
    queryString: queryString ? `${URL}?${queryString}` : URL,
  });

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": BLOG_API_KEY,
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    //body: JSON.stringify(data),
  };

  const response = await fetch(
    queryString ? `${URL}?${queryString}` : URL,
    requestOptions
  );
  const result = await getResultOrError(response);

  LOGGER("RESULT:", result);

  return result;
};

const BlogService = {
  getAll,
  getBlogPosts,
  getSingleBlogPostBySlug,
  getBlogPostMetaBySlug,
  getRelatedBlogPosts,
  getBlogCategories,
  getBlogCategory,
  getBlogTopPosts,
  getBlogTags,
  getBlogTag,
  getBlogTopPostsByAuthor,
  getBlogPostsByCategory,
  getSearchByTitle,
};

export default BlogService;
