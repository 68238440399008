import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="search-not-found text-center spacing-bottom-xlg">
      <picture>
        <source
          srcSet="https://sendpulse.com/blog/wp-content/themes/sendpulse_v2/assets/img/search.svg"
          type="image/webp"
        />
        <img
          className="search-not-found__img"
          src="https://sendpulse.com/blog/wp-content/themes/sendpulse_v2/assets/img/search.svg"
          alt="nothing found"
          width="132"
          height="98"
          style={{ display: "inline" }}
        />
      </picture>
      <h2 className="search-not-found__title">
        К сожалению ничего не удалось найти
      </h2>
      <p className="search-not-found__descr">
        <Link to="/blog">Вернуться в блог</Link>
      </p>
    </div>
  );
};

export default NotFound;
