import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetSearch = (params = {}, options = {}) => {
  console.log(
    "!",
    typeof params !== typeof undefined,
    params.title.length,
    params?.title && params?.title.length >= 3
  );

  const isTitleSet = params.title.length >= 3;

  console.log(isTitleSet);

  return useQuery({
    queryFn: () => BlogService.getSearchByTitle(params),
    queryKey: ["blog_search_posts", params],
    enabled: typeof params !== typeof undefined && isTitleSet,
  });
};
