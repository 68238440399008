import ProgressiveImg2 from "components/utils/Image/ProgressiveImg2";
import { BLOG_API_URI } from "config/config";
import { Link } from "react-router-dom";

import placeholderSrc from "assets/images/placeholders/placeholder_900x900.webp";

const POST_URL = `${BLOG_API_URI}/posts`;

/**
 * Component for showing Component "Blog post image" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostImage = (props) => {
  const { data } = props;

  return (
    <div className="blog-image">
      <Link
        to={`/blog/${data.slug}`}
        aria-label={`post_${data.id}`}
        className="blog-image-wrap"
      >
        <ProgressiveImg2
          hightSrc={`${POST_URL}/imagedb/${data.imageURL}`}
          lowSrc={placeholderSrc}
          alt={data?.imageAlt || data.title}
          loading="lazy"
          className=""
        />
      </Link>
    </div>
  );
};

export default BlogPostImage;
