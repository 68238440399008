import { EventGA } from "components/utils/Analytics";
import { AnalyticsEvent } from "components/utils/Analytics/Analytics";
import { isDevelopment } from "components/utils/Utils";
import { APP_API_URI, BLOG_API_KEY } from "config/config";
import { _debug } from "utils";

export const requestGlobalOptions = {
  headers: undefined,
  // no-cors, *cors, same-origin
  mode: "cors",
  // *default, no-cache, reload, force-cache, only-if-cached
  cache: isDevelopment ? "no-cache" : "default",
  // include, *same-origin, omit
  credentials: "include",
};

export const requestGlobalHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Api-Key": BLOG_API_KEY,
};

export const getGlobalAuthHeader = () => {
  var localData = localStorage.getItem("authData");
  try {
    const authData = JSON.parse(localData);

    if (authData && authData.data && authData.data.accessToken) {
      const header = {
        Authorization: `Bearer ${authData.data.accessToken}`,
        "X-Api-Key": BLOG_API_KEY,
      };
      return header;
      // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    }
    const header = {
      "X-Api-Key": BLOG_API_KEY,
    };

    return header;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export class FetchError extends Error {
  constructor(res, message) {
    super(message);
  }
}

export const createGiftOrder = async (data) => {
  // const bodyExtended = JSON.stringify({ ...data });

  const requestBody = {
    customer: "",
    product: "732fc688-79a8-426f-bfaf-dcec198bbb42",
    deliveryName: data.name,
    deliveryEmail: data.email,
    description: "",
  };

  _debug("createGiftOrder:", data);
  _debug(requestBody);

  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const requestOptions = {
    method: "POST",
    headers: new Headers(requestHeaders),
    // no-cors, *cors, same-origin
    mode: "cors",
    // *default, no-cache, reload, force-cache, only-if-cached
    cache: isDevelopment ? "no-cache" : "default",
    // include, *same-origin, omit
    credentials: "include",
    body: JSON.stringify(requestBody),
  };

  console.log(requestOptions);

  // GA
  await EventGA(
    AnalyticsEvent.contact.category,
    AnalyticsEvent.contact.action,
    AnalyticsEvent.contact.label
  );

  const response = await fetch(
    `${APP_API_URI}/payments/payment/v2`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error(`HTTP error: Status ${response.status}`);
  }

  return await response.json();
};

export const customerLogin = async (data) => {
  _debug("Customer login API:", data, "info");

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(headers),
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${APP_API_URI}/auth/customer/login`,
    requestOptions
  );

  if (response.ok && response.status === 200) {
    return await response.json();
  }

  const result = await response.json();
  const responseError = {
    type: "Error",
    message: result.message || "Something went wrong",
    data: result.data || "",
    code: result.code || "",
    status: response.status || 500,
  };

  let error = new Error("HTTP error");
  error = { ...error, ...responseError };
  throw error;
};

export const customerRegister = async (data) => {
  _debug("Customer register API:", data);

  const headers = {
    ...requestGlobalHeaders,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(headers),
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${APP_API_URI}/auth/customer/register`,
    requestOptions
  );
  console.log(response);
  if (!response.ok) {
    if (response.status === 404) throw new Error("404, Not found");
    if (response.status === 500) throw new Error("500, internal server error");
    throw new Error(`HTTP error: Status ${response.status}`);
  }

  return await response.json();
};

export const customerGetMe = async () => {
  _debug("Customer get me API");

  const URL = `${APP_API_URI}/auth/customer/me`;

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);

  if (response.ok && response.status === 200) {
    // return data ? JSON.parse(data) : {};
    return response.json();
  } else if (response.ok && response.status === 204) {
    //throw new FetchError(response);
    const responseError = {
      type: "Error",
      message: "No content",
      data: [],
      code: response.code || "",
      status: response.status || 500,
    };

    let error = new Error("HTTP error");
    error = { ...error, ...responseError };
    throw error;
  }
  //}
  // if (!response.ok) {
  //   throw new Error(`HTTP error: Status ${response.status}`);
  // }
  const result_2 = await response.text();
  const responseError_1 = {
    type: "Error",
    message: result_2.message || "Something went wrong",
    data: undefined || "",
    code: "" || "",
    status: response.status || 500,
  };
  let error_1 = new Error("HTTP error");
  error_1 = { ...error_1, ...responseError_1 };
  throw error_1;
};

export const psychologistLogin = async (data) => {
  _debug("Psychologist login API:", data);

  const URL = `${APP_API_URI}/auth/psychologist/login`;

  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  const response = await fetch(URL, requestOptions);

  if (response.ok && response.status === 200) {
    return await response.json();
  }

  const result = await response.json();
  const responseError = {
    type: "Error",
    message: result.message || "Something went wrong",
    data: result.data || "",
    code: result.code || "",
    status: response.status || 500,
  };

  let error = new Error("HTTP error");
  error = { ...error, ...responseError };
  throw error;
};

export const psychologistRegister = async (data) => {
  _debug("Psychologist register API:", data);

  const URL = `${APP_API_URI}/auth/psychologist/register`;

  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "POST",
    headers: new Headers(requestHeaders),
    body: JSON.stringify(data),
  };

  const response = await fetch(URL, requestOptions);

  if (response.ok && response.status === 200) {
    return await response.json();
  }

  const result = await response.json();
  const responseError = {
    type: "Error",
    message: result.message || "Something went wrong",
    data: result.data || "",
    code: result.code || "",
    status: response.status || 500,
  };

  let error = new Error("HTTP error");
  error = { ...error, ...responseError };
  throw error;
};

export const psychologistGetMe = async () => {
  _debug("Psychologist get me API");

  const URL = `${APP_API_URI}/auth/psychologist/me`;

  let headers = getGlobalAuthHeader();
  const requestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    ...requestGlobalOptions,
    method: "GET",
    headers: new Headers(requestHeaders),
    body: undefined,
  };

  const response = await fetch(URL, requestOptions);

  if (response.ok && response.status === 200) {
    // return data ? JSON.parse(data) : {};
    return response.json();
  } else if (response.ok && response.status === 204) {
    //throw new FetchError(response);
    const responseError = {
      type: "Error",
      message: "No content",
      data: [],
      code: response.code || "",
      status: response.status || 500,
    };

    let error = new Error("HTTP error");
    error = { ...error, ...responseError };
    throw error;
  }

  const result_2 = await response.text();
  const responseError_1 = {
    type: "Error",
    message: result_2.message || "Something went wrong",
    data: undefined || "",
    code: "" || "",
    status: response.status || 500,
  };
  let error_1 = new Error("HTTP error");
  error_1 = { ...error_1, ...responseError_1 };
  throw error_1;
};

export const logout = async () => {
  _debug("Logout API");

  const URL = `${APP_API_URI}/auth/psychologist/logout`;
};

const AuthService = {
  customerLogin,
  customerRegister,
  customerGetMe,
  psychologistLogin,
  psychologistRegister,
  psychologistGetMe,
  logout,
};

export default AuthService;
