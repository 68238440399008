import classNames from "classnames";
import BlogPostCard2 from "components/pages/Blog/components/BlogPostCard2";
import { BLOG_ID, SITE } from "config/config";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { dataMessages } from "data";
import { DOTS, usePagination } from "hooks";
import { debounce } from "lodash";
import { PropTypes } from "prop-types";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetSearch } from "../utils";
import NotFound from "./NotFound";

// NOTE :

const msgTitle = "Категории";
const msgNoData = "Нет данных о категориях в блоге";

const PAGES_LIMIT = SITE.BLOG.PAGES_LIMIT;
const DEFAULT_PAGES_LIMIT = 6;
const initialState = {
  pageNo: 0,
  totalPages: 0,
  totalItems: 0,
  pageSize: PAGES_LIMIT || DEFAULT_PAGES_LIMIT,
  blog: BLOG_ID,
};

const BlogListPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex - 1);
  };

  const onPrevious = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex + 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  // Old function to delete: 16/12/2024
  const renderPaginationItems_old = () => {
    let content = [];
    for (let index = 0; index < totalCount; index++) {
      //console.log(index);
      //const item = animals[i];
      content.push(
        <Pagination.Item
          onClick={() => onPageChange(index)}
          key={index}
          active={index === currentPage - 1}
        >
          {index + 1}
        </Pagination.Item>
      );
    }
    return content;
  };

  const renderPaginationItems = () => {
    return (
      <Fragment>
        {paginationRange.map((pageNumber, index) => (
          <Fragment key={index}>
            {pageNumber === DOTS ? (
              <Pagination.Ellipsis key={index} className="dots">
                &#8230;
              </Pagination.Ellipsis>
            ) : (
              <Pagination.Item
                key={index}
                className={classNames(
                  "",
                  {
                    selected: pageNumber === currentPage,
                  },
                  {
                    active: pageNumber === currentPage,
                  }
                )}
                onClick={() => onPageChange(index)}
              >
                {pageNumber}
              </Pagination.Item>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <Pagination className="d-flex justify-content-center align-items-center">
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => onPageChange(0)}
      />
      <Pagination.Prev disabled={currentPage === 1} onClick={() => onNext()} />
      {renderPaginationItems()}
      <Pagination.Next
        disabled={currentPage === lastPage}
        onClick={() => onPrevious()}
      />
      <Pagination.Last
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(lastPage - 1)}
      />
    </Pagination>
  );
};

/**
 * Component for showing section "Search Results" of the site.
 *
 * @component
 * @version: 1.0.0
 **/

const SectionSearchResult = (props) => {
  const {
    data,
    id = "search-result",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    searchField = "",
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  const [searchShow, setSearchShow] = useState(false);
  // const [searchField, setSearchField] = useState("");
  // const [searchField2, setSearchField2] = useState("");

  const [pageState, setPageState] = useState(initialState);

  const {
    data: blogPostsPaged,
    isLoading: isLoadingBlogPosts,
    isError: isErrorBlogPosts,
    error: errorBlogPosts,
  } = useGetSearch({
    blog: BLOG_ID,
    title: searchField,
    pageNo: pageState.pageNo || 0,
    pageSize: pageState.pageSize || DEFAULT_PAGES_LIMIT,
  });

  const isLoading = isLoadingBlogPosts;
  const isError = isErrorBlogPosts;
  const errors = [errorBlogPosts];

  const onSearch = (searchField2) => {
    // searchField2
  };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      // setSearchField2(searchTerm);
      // onSearch(searchTerm);
    }, 500), // Debounce for 300 milliseconds
    [onSearch]
  );

  // const handleSearchChange = (value) => {
  //   setSearchField(value);
  //   handleSearch(value);
  //   if (value === "") {
  //     setSearchShow(false);
  //   } else {
  //     setSearchShow(true);
  //   }
  // };

  const handleChangeCurrentPage = (_pageNumber) => {
    setPageState((prev) => ({ ...prev, pageNo: _pageNumber }));
  };

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (blogPostsPaged) {
      _debug("BLOG POSTS SEARCH:", blogPostsPaged);
    }
  }, [blogPostsPaged]);

  // // Show a loading message while data is fetching
  // if (isLoading) {
  //   return <Spinner hidden={!isLoading} />;
  // }

  // if (isError && errorBlogPosts?.status === 204) {
  //   return <div className="error">{msgNoData}</div>;
  // }

  const isList = false;

  return (
    <PageSection id={id} cn={classNames(prefix, cn)} container>
      {isError && errorBlogPosts?.status === 204 && <NotFound />}

      {!isLoading && (
        <Fragment>
          {showHeader && (
            <SectionHeader
              data={data}
              className=""
              showSubtitle={showHeaderSubtitle}
              showDescription={showHeaderDescription}
            />
          )}
          <div className="post-list-wrapper">
            {/* Posts */}
            {isList ? (
              blogPostsPaged &&
              blogPostsPaged.content &&
              blogPostsPaged.content.length &&
              blogPostsPaged.content.map((item, index) => (
                <BlogPostCard2 data={item} key={index} />
              ))
            ) : (
              <div className="row gx-md-5 gy-5 mb-5">
                {blogPostsPaged &&
                  blogPostsPaged.content &&
                  blogPostsPaged.content.length &&
                  blogPostsPaged.content.map((item, index) => (
                    <div className="col-md-6" key={index}>
                      <BlogPostCard2 data={item} />
                    </div>
                  ))}
              </div>
            )}

            {/* Pagination */}
            <div className="pagination-wrapper">
              <BlogListPagination
                currentPage={pageState.pageNo + 1}
                totalCount={pageState.totalItems}
                pageSize={pageState.pageSize}
                siblingCount={1}
                onPageChange={handleChangeCurrentPage}
              />
            </div>
          </div>
        </Fragment>
      )}
    </PageSection>
  );
};

SectionSearchResult.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeader: PropTypes.bool ?? true,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionSearchResult;
