import classNames from "classnames";
import { BLOG_ID, SITE } from "config/config";
import { PageSection, SectionHeader } from "core/components";
import { useSectionPrefix } from "core/components/Section";
import { DOTS, usePagination } from "hooks";
import { debounce } from "lodash";
import { PropTypes } from "prop-types";
import { Fragment, useCallback, useState } from "react";
import { Pagination, Row } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

// NOTE :

const msgTitle = "Категории";
const msgNoData = "Нет данных о категориях в блоге";

const PAGES_LIMIT = SITE.BLOG.PAGES_LIMIT;
const DEFAULT_PAGES_LIMIT = 6;
const initialState = {
  pageNo: 0,
  totalPages: 0,
  totalItems: 0,
  pageSize: PAGES_LIMIT || DEFAULT_PAGES_LIMIT,
  blog: BLOG_ID,
};

// function searchList() {
//   return (
//     <Scroll>
//       <SearchList filteredPersons={filteredPersons} />
//     </Scroll>
//   );
// }

const BlogListPagination_del = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex - 1);
  };

  const onPrevious = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex + 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  // Old function to delete: 16/12/2024
  const renderPaginationItems_old = () => {
    let content = [];
    for (let index = 0; index < totalCount; index++) {
      //console.log(index);
      //const item = animals[i];
      content.push(
        <Pagination.Item
          onClick={() => onPageChange(index)}
          key={index}
          active={index === currentPage - 1}
        >
          {index + 1}
        </Pagination.Item>
      );
    }
    return content;
  };

  const renderPaginationItems = () => {
    return (
      <Fragment>
        {paginationRange.map((pageNumber, index) => (
          <Fragment key={index}>
            {pageNumber === DOTS ? (
              <Pagination.Ellipsis key={index} className="dots">
                &#8230;
              </Pagination.Ellipsis>
            ) : (
              <Pagination.Item
                key={index}
                className={classNames(
                  "",
                  {
                    selected: pageNumber === currentPage,
                  },
                  {
                    active: pageNumber === currentPage,
                  }
                )}
                onClick={() => onPageChange(index)}
              >
                {pageNumber}
              </Pagination.Item>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <Pagination className="d-flex justify-content-center align-items-center">
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => onPageChange(0)}
      />
      <Pagination.Prev disabled={currentPage === 1} onClick={() => onNext()} />
      {renderPaginationItems()}
      <Pagination.Next
        disabled={currentPage === lastPage}
        onClick={() => onPrevious()}
      />
      <Pagination.Last
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(lastPage - 1)}
      />
    </Pagination>
  );
};

const SectionContent = (props) => {
  const { data, searchField, onSearchChanged } = props;

  // const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  // initialize the loading state as true
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    onSearchChanged && onSearchChanged(e.target.value);
    // setSearchField(e.target.value);
    // if (e.target.value === "") {
    //   setSearchShow(false);
    // } else {
    //   setSearchShow(true);
    // }
  };

  return (
    <Row className="">
      <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <section className="kb-search section-spacing--xs">
          <div className="custom-search kb-search__search">
            <form method="get" action="">
              <div className="input-group input-group--email">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Поиск..."
                  name="q"
                  value={searchField}
                  required=""
                  maxLength="200"
                  minLength="3"
                  onChange={handleChange}
                />
                <button className="btn btn--search" type="submit">
                  <BiSearch width="24" height="24" fill="currentColor" />
                </button>
              </div>
            </form>
          </div>
          {/* <div className="kb-search__wrap">
            <div className="kb-search__header">
              <h2 className="kb-search__title">Результаты поиска</h2>
            </div>
            <NotFound />
          </div> */}
        </section>
        <div className=""></div>
      </div>
    </Row>
  );
};

/**
 * Component for showing section "Search" of the site.
 *
 * @component
 * @version: 1.0.0
 **/

const SectionSearch = (props) => {
  const {
    data,
    id = "search",
    cn = "",
    type,
    showHeader = false,
    showHeaderSubtitle = false,
    showHeaderDescription = false,
    showContentTitle = true,
    showContentSubtitle = false,
    showContentDescription = true,
    useAnimation = false,
    innerRef,
    onSearchChange,
    ...otherProps
  } = props;
  const prefix = useSectionPrefix(id, type);

  // const [searchShow, setSearchShow] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [searchField2, setSearchField2] = useState("");

  // const onSearch = (searchField2) => {
  //   // searchField2
  // };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      // setSearchField2(searchTerm);
      onSearchChange(searchTerm);
    }, 800), // Debounce for 800 milliseconds
    [onSearchChange]
  );

  const handleSearchChange = (value) => {
    setSearchField(value);
    handleSearch(value);
    // if (value === "") {
    //   setSearchShow(false);
    // } else {
    //   setSearchShow(true);
    // }
  };

  return (
    <Fragment>
      <PageSection id={id} cn={classNames(prefix, cn)} container>
        {showHeader && (
          <SectionHeader
            data={data}
            className=""
            showSubtitle={showHeaderSubtitle}
            showDescription={showHeaderDescription}
          />
        )}
        <SectionContent
          data={data}
          id={id}
          cn={cn}
          type={type}
          showTitle={showContentTitle}
          showSubtitle={showContentSubtitle}
          showDescription={showContentDescription}
          searchField={searchField}
          onSearchChanged={handleSearchChange}
          {...otherProps}
        />
      </PageSection>
    </Fragment>
  );
};

SectionSearch.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
  type: PropTypes.oneOf(["style1", "style2"]),
  showHeader: PropTypes.bool ?? true,
  showHeaderSubtitle: PropTypes.bool ?? true,
  showHeaderDescription: PropTypes.bool ?? false,
  showContentTitle: PropTypes.bool ?? true,
  showContentSubtitle: PropTypes.bool ?? false,
  showContentDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionSearch;
