import { Spinner } from "components/utils/Spinner";
import { dataMessages } from "data";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { _debug } from "utils";
import { useGetBlogTags } from "../utils";
import SidebarBox from "./SidebarBox";

const msgTitle = "Популярные тэги";
const msgNoData = "Нет данных о тэгах в блоге";

/**
 * Component for showing Component "Sidebar box post top tags" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogBoxTopTags = () => {
  const {
    data: blogTags,
    isLoading: isLoadingBlogTags,
    isError: isErrorBlogTags,
    error: errorBlogTags,
  } = useGetBlogTags();

  const isLoading = isLoadingBlogTags;
  const isError = isErrorBlogTags;
  const errors = [errorBlogTags];

  useEffect(() => {
    if (isError) {
      errors.forEach((err) => {
        if (err) {
          console.error(err);
          if (err?.status === 204) {
            toast.info(`${dataMessages.msgError}: ${err?.message}`);
          } else {
            toast.error(`${dataMessages.msgError}: ${err?.message}`);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (blogTags) {
      _debug("BLOG TAGS:", blogTags);
    }
  }, [blogTags]);

  // Show a loading message while data is fetching
  if (isLoading) {
    return <Spinner hidden={!isLoading} />;
  }

  if (isError && errorBlogTags?.status === 204) {
    return <div className="error">{msgNoData}</div>;
  }

  if (!blogTags) {
    return <div className="error">{msgNoData}</div>;
  }

  return (
    <SidebarBox title={msgTitle} cn="tags-box mb-3">
      <ul className="post-tags unordered_list">
        {blogTags &&
          blogTags.slice(0, 5).map((item, index) => (
            <li key={index}>
              <Link to={`/blog/tag/${item.slug}`}>{item.name}</Link>
            </li>
          ))}
      </ul>
    </SidebarBox>
  );
};

export default BlogBoxTopTags;

// const URL = `${BLOG_API_URI}/tags/all?blog=${BLOG_ID}`;
// const { data: tagsData, isLoading, hasError, errorMessage } = useFetch(URL);
// const tagsDataTop = tagsData ? tagsData.slice(0, 3) : undefined;

// _debug(URL);

// useEffect(() => {
//   if (tagsData) _debug("Data fetched: ", tagsData);
//   if (hasError) _debug("Error: ", errorMessage);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [isLoading, hasError, tagsData]);

// if (isLoading || hasError) {
//   return (
//     <Fragment>
//       {isLoading && <Spinner />}
//       {hasError && <p>{errorMessage}</p>}
//     </Fragment>
//   );
// }

// if (!tagsData) {
//   return <h5>No tags founds</h5>;
// }
