import CustomSwiper from "components/core/Swiper";
import BlogPostCard2 from "components/pages/Blog/components/BlogPostCard2";
import { PropTypes } from "prop-types";

// Import Swiper React components
import { SwiperSlide } from "swiper/react";
// import required modules

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// NOTE :

/**
 * Component section content "Top Posts (style 2)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle2 = (props) => {
  const {
    data: posts,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;
  // console.log("!!!!!!!!!!!!!!");

  const items =
    posts &&
    posts.length > 0 &&
    posts.map((item, index) => {
      // console.log(item);
      return (
        <SwiperSlide key={index}>
          <BlogPostCard2 data={item} key={index} />
        </SwiperSlide>
      );
    });

  return (
    <div className="row">
      <div className="col-12">
        <div className="section-content slider">
          <CustomSwiper renderItems={items} />
        </div>
      </div>
    </div>
  );
};

SectionContentStyle2.propTypes = {
  data: PropTypes.array,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle2;
