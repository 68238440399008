import { PropTypes } from "prop-types";

// Import Swiper React components
import { SwiperSlide } from "swiper/react";
// import required modules

// Import Swiper styles
import CustomSwiper from "components/core/Swiper";
import { BlogPostCard } from "components/pages/Blog/components";
import "swiper/css";
import "swiper/css/pagination";

// NOTE :

/**
 * Component section content "Top Posts (style 1)" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const SectionContentStyle1 = (props) => {
  const {
    data: posts,
    id,
    cn,
    type,
    showTitle = true,
    showSubtitle = true,
    showDescription = true,
    useAnimation,
    innerRef,
    ...otherProps
  } = props;

  const items =
    posts &&
    posts.length > 0 &&
    posts.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <article
            itemProp="blogPosts"
            itemScope
            itemType="http://schema.org/BlogPosting"
          >
            <BlogPostCard data={item} key={index} />
          </article>
        </SwiperSlide>
      );
    });

  return (
    <div className="row">
      <div className="col-12">
        <div className="section-content slider">
          <CustomSwiper renderItems={items} />
        </div>
      </div>
    </div>
  );
};

SectionContentStyle1.propTypes = {
  data: PropTypes.array,
  id: PropTypes.string,
  cn: PropTypes.string,
  showTitle: PropTypes.bool ?? true,
  showSubtitle: PropTypes.bool ?? false,
  showDescription: PropTypes.bool ?? true,
  useAnimation: PropTypes.bool ?? false,
};

export default SectionContentStyle1;
