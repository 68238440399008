import { SectionHeader } from "components/sections/CommonSection";
import { chunkArray } from "components/utils/Utils";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

import { DetailsSection } from "../Details.section";
import "./TherapistWorkingWith.style.scss";

// NOTE :

const SectionContent = (props) => {
  const { data, isMobile } = props;

  // console.log(data);

  const groups = chunkArray(data.specializations, 2);

  return (
    <Fragment>
      <div className="bg-gray-0 py-6 py-md-7">
        <div className="container profile-layout-item">
          <div className="psy-profile-competences__header">С чем поможет</div>
          <div className="mb-5 mb-md-3">
            <div className="row">
              {groups.map((itemsGroup, indexGroup) => {
                return (
                  <div className="col-md-6 col-sm-6" key={indexGroup}>
                    <ul className="info_list unordered_list_block mb-md-4">
                      {itemsGroup.map((items, index) => (
                        <li key={index}>
                          <span className="info_icon">
                            <i className="fa-light fa-circle-check"></i>
                          </span>
                          <span className="info_text">{items.title}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}

              <ul className="psy-profile-competences__list-ul list-disc pl-3">
                {data.specializations &&
                  data.specializations.length > 0 &&
                  data.specializations.map((item, index) => {
                    return (
                      <li
                        className="mb-3 psy-profile-competences__list-item"
                        key={index}
                      >
                        <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
                          {item.title}
                        </span>
                      </li>
                    );
                  })}

                {/*<li className="mb-3 psy-profile-competences__list-item">
                  <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
                    Отношения с партнером
                  </span>
                </li>
                <li className="mb-3 psy-profile-competences__list-item">
                  <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
                    Развод
                  </span>
                </li>
                <li className="mb-3 psy-profile-competences__list-item">
                  <span className="font-futura-400 line-height-2 font-weight-0 font-size-4 color-blue-4">
                    Конфликты
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="psy-profile-competences__header">С кем работает</div>
          <div className="sb-3 d-flex flex-wrap">
            <span className="d-flex align-items-center flex-nowrap p-2 border-radius-3 bg-white ">
              <span className="font-futura-400 mr-2 line-height-2 font-weight-1 font-size-4 color-blue-4">
                Взрослые
              </span>
              <img
                src="https://alter.ru/images/man-tipping-hand-emoji.png"
                alt=""
                style={{ width: "18px", height: "18px" }}
              />
            </span>
            <span className="d-flex align-items-center flex-nowrap p-2 border-radius-3 bg-white ">
              <span className="font-futura-400 mr-2 line-height-2 font-weight-1 font-size-4 color-blue-4">
                Семьи
              </span>
              <img
                src="https://alter.ru/images/family-emoji.png"
                alt=""
                style={{ width: "18px", height: "18px" }}
              />
            </span>
            <span className="d-flex align-items-center flex-nowrap p-2 border-radius-3 bg-white ">
              <span className="font-futura-400 mr-2 line-height-2 font-weight-1 font-size-4 color-blue-4">
                Люди в возрасте
              </span>
              <img
                src="https://alter.ru/images/older-emoji.png"
                alt=""
                style={{ width: "18px", height: "18px" }}
              />
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Component for showing section "Therapist tWorking With" of the site.
 *
 * @component
 */
const TherapistWorkingWith = (props) => {
  const { data, id = "therapist-methods", cn = "" } = props;

  return (
    <Fragment>
      {/* <PageSection id={id} cn={cn} style={{ backgroundColor: "#eff2f9" }} >*/}
      <DetailsSection id={id} cn={cn}>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent data={data.therapist} isMobile={false} />
        </div>
      </DetailsSection>
    </Fragment>
  );
};

TherapistWorkingWith.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  cn: PropTypes.string,
};

export default TherapistWorkingWith;
