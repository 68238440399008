import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetMetaBlogPost = (slug, options = {}) => {
  // console.log("object", slug, options);
  return useQuery({
    queryFn: () => BlogService.getBlogPostMetaBySlug(slug),
    queryKey: ["blog_single_post_meta", slug],
    enabled: typeof slug !== typeof undefined,
  });
};
