import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogTag = (params, options = {}) => {
  // console.log(params);
  return useQuery({
    queryFn: () => BlogService.getBlogTag(params),
    queryKey: ["blog_tag", params],
    // enabled: typeof params.slug !== typeof undefined,
  });
};
