import { SITE } from "config/config";

const dataPageTitle = "";
const dataPageDescription = "";
const dataPageAuthor = SITE.AUTHOR;

const dataMetaOGTitle = dataPageTitle;
const dataMetaOGDescription = dataPageDescription;
const dataMetaOGImage = "https://psywealth.com/images/OG/main_1200x640.png";
const dataMetaOGImageAlt = "";
const dataMetaOGLocale = SITE.LOCALE;
const dataMetaOGSiteName = SITE.NAME;

const dataMetaTWImage = dataMetaOGImage;
const dataMetaTWCreator = SITE.URL;
const dataMetaTWDomain = SITE.URL;

export const dataMetaGlossaryPage = {
  title: dataPageTitle,
  description: dataPageDescription,
  name: "",
  type: "",
  author: dataPageAuthor,
  ExtraMetaTags: [],
  OG: [
    {
      property: "og:title",
      content: dataMetaOGTitle,
    },
    {
      property: "og:description",
      content: dataMetaOGDescription,
    },
    {
      property: "og:url",
      content: "",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:image",
      content: dataMetaOGImage,
    },
    {
      property: "og:image:alt",
      content: dataMetaOGImageAlt,
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "640",
    },
    {
      property: "og:locale",
      content: dataMetaOGLocale,
    },
    {
      property: "og:site_name",
      content: dataMetaOGSiteName,
    },
  ],
  TW: [
    {
      name: "twitter:title",
      content: dataPageTitle,
    },
    {
      name: "twitter:description",
      content: dataPageDescription,
    },
    { property: "twitter:card", content: "summary_large_image" },
    // { property: "twitter:label1", content: "Примерное время для чтения" },
    // { property: "twitter:data1", content: "10 минуты" },
    {
      name: "twitter:image:src",
      content: dataMetaTWImage,
    },
    {
      name: "twitter:creator",
      content: dataMetaTWCreator,
    },
    {
      name: "twitter:domain",
      content: dataMetaTWDomain,
    },
  ],
};
