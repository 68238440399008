import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogCategory = (params, options = {}) => {
  // console.log(params);
  return useQuery({
    queryFn: () => BlogService.getBlogCategory(params),
    queryKey: ["blog_category", params],
    // enabled: typeof params.slug !== typeof undefined,
  });
};
