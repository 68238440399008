import { Pagination } from "react-bootstrap";

/**
 * Component for component "List Pagination" of the site.
 *
 * @component
 */

const ListPagination = (props) => {
  const { pages, onPageChange } = props;

  const handlePageChange = (index) => {
    onPageChange && onPageChange(index);
  };

  const getPaginationItems = (items) => {
    let content = [];
    for (let index = 1; index <= pages.totalPages; index++) {
      //console.log(index);
      //const item = animals[i];
      content.push(
        <Pagination.Item
          onClick={() => handlePageChange(index)}
          key={index}
          active={index === pages.page + 1}
        >
          {index}
        </Pagination.Item>
      );
    }
    return content;
  };

  return (
    <div className="pagination">
      <Pagination className="d-flex justify-content-center align-items-center px-4">
        <Pagination.First
          disabled={pages.page === 0}
          onClick={() => handlePageChange(1)}
        />
        {getPaginationItems()}
        <Pagination.Last
          disabled={pages.page === pages.totalPages - 1}
          onClick={() => handlePageChange(pages.totalPages)}
        />
      </Pagination>
    </div>
  );
};

export default ListPagination;
