import { useDebounce } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const cityValues = [
  { title: "Москва", selected: false },
  { title: "Санкт-Петербург", selected: false },
  { title: "Тверь", selected: true },
];
const sexValues = [
  { title: "Мужчина", selected: false },
  { title: "Женщина", selected: false },
  { title: "Не имеет значения", selected: true },
];
const forWhomValues = ["Для себя", "Для ребенка", "Для пары", "Для семьи"];
// const sexForWhom = ["Для себя", "Для ребенка", "Для пары"];

const ProductListFilters = ({ onChange }) => {
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search);

  const [category, setCategory] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [sex, setSex] = useState();
  const [forWhom, setForWhom] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    onChange({
      category,
      maxPrice,
      search: debouncedSearch,
      sex,
      forWhom,
      city,
    });
    console.log(sex);
  }, [category, debouncedSearch, maxPrice, sex, forWhom, city]);

  return (
    <Fragment>
      {/* <div className="flex flex-row gap-2">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search products"
        />
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="first">First</option>
          <option value="second">Second</option>
          <option value="third">Third</option>
        </select>
        <select
          value={maxPrice}
          onChange={(e) =>
            setMaxPrice(e.target.value ? parseInt(e.target.value) : undefined)
          }
        >
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
      </div> */}

      <div className="row">
        <div className="col-md-12">
          <Form>
            <div className="card acik-renk-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                      aria-label="Default select example"
                    >
                      <Form.Select
                        id="arac-turu"
                        className=""
                        value={city}
                        defaultValue={"Москва"}
                        onChange={(e) => {
                          //   console.log(e);
                          setCity(e.target.value ? e.target.value : undefined);
                        }}
                      >
                        {cityValues.map((item, index) => {
                          return item?.selected ? (
                            <option selected key={index}>
                              {item.title}
                            </option>
                          ) : (
                            <option key={index}>{item.title}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                      aria-label="Default select example"
                    >
                      <Form.Select
                        id="arac-turu"
                        className=""
                        value={forWhom}
                        defaultValue={"Для себя"}
                        onChange={(e) => {
                          //   console.log(e);
                          setForWhom(
                            e.target.value ? e.target.value : undefined
                          );
                        }}
                      >
                        {forWhomValues.map((item, index) => {
                          return item?.selected ? (
                            <option selected key={index}>
                              {item}
                            </option>
                          ) : (
                            <option key={index}>{item}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                      aria-label="Default select example"
                    >
                      <Form.Select
                        id="arac-turu"
                        className=""
                        value={sex}
                        defaultValue={"Не имеет значения"}
                        onChange={(e) => {
                          //   console.log(e);
                          setSex(e.target.value ? e.target.value : undefined);
                        }}
                      >
                        {sexValues.map((item, index) => {
                          return item.selected ? (
                            <option selected key={index}>
                              {item.title}
                            </option>
                          ) : (
                            <option key={index}>{item.title}</option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductListFilters;
