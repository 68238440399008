import { Link, useLocation } from "react-router-dom";

import Image1 from "assets/images/new/shape_leaf_left.svg";
import Image2 from "assets/images/new/shape_leaf_right.svg";
import { getNameByPath } from "components/utils/Utils";
import { Fragment, useMemo } from "react";
import { Breadcrumb } from "react-bootstrap";

/**
 * Component for showing section "Page Banner" of the site.
 *
 * @component
 * @version: 2.0.2
 **/
const Banner2 = (props) => {
  const { data, isSingleBlog = false, bannerData } = props;
  const location = useLocation();

  const breadcrumbs = useMemo(() => {
    const asPathWithoutQuery = location.pathname.split("?")[0];
    const asPathNestedRoutes = asPathWithoutQuery
      .split("/")
      .filter((v) => v.length > 0);

    const crumblist = asPathNestedRoutes.map((subpath, index) => {
      const href = `/${subpath}`;
      return { href, text: getNameByPath(subpath, href) };
    });

    return [{ href: "/", text: "Home" }, ...crumblist];
  }, [location]);

  const renderBreadcrumb = () => {
    return (
      <Breadcrumb
        className=""
        listProps={{
          className: "justify-content-lg-end justify-content-center",
          itemType: "http://schema.org/BreadcrumbList",
          itemScope: "itemScope",
        }}
      >
        {breadcrumbs.map((item, index) => {
          const isLast = index === breadcrumbs.length - 1;
          // console.log(isLast, item, index);

          if (isLast) {
            return (
              <Breadcrumb.Item key={index} active>
                {item.text}
              </Breadcrumb.Item>
            );
          }

          return (
            <Breadcrumb.Item
              key={index}
              itemType="https://schema.org/ListItem"
              itemProp="itemListElement"
              itemScope
              linkProps={{
                to: item.href,
                title: "Основной раздел",
                itemProp: "item",
              }}
              linkAs={Link}
            >
              <span itemProp="name">{item.text}</span>
              <meta itemProp="position" content={index + 1}></meta>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  return (
    <section className="page-banner-section-style2 pt-120 pb-75 decoration-wrapper">
      <div className="container">
        <div className="row align-items-center">
          {isSingleBlog ? (
            <Fragment>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <Link to={`/blog/category/${bannerData.slug}`} className="h1">
                    {bannerData.title}
                  </Link>
                </div>
                <div className="col-md-6">{renderBreadcrumb()}</div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h1 className="page-title mb-0">{data.title}</h1>
                </div>
                <div className="col-lg-6">{renderBreadcrumb()}</div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <div className="decoration-item shape_leaf_1">
        <img src={Image1} alt="Shape Leaf - Talking Minds - Psychotherapist" />
      </div>
      <div className="decoration-item shape_leaf_2">
        <img src={Image2} alt="Shape Leaf - Talking Minds - Psychotherapist" />
      </div>
    </section>
  );
};

export default Banner2;
