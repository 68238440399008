import PropTypes from "prop-types";
import BlogListPagination from "./BlogListPagination";
import BlogPostCard2 from "./BlogPostCard2";

/**
 * Component for showing Component "Post List with Pagination" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostListWithPagination = (props) => {
  const { baseURL, blogPosts, pageState, isList = false, onPageChange } = props;

  return (
    <div className="post-list-wrapper">
      {/* Posts */}
      {isList ? (
        blogPosts &&
        blogPosts.content &&
        blogPosts.content.length &&
        blogPosts.content.map((item, index) => (
          <BlogPostCard2 data={item} key={index} />
        ))
      ) : (
        <div className="row gx-md-5 gy-5 mb-5">
          {blogPosts &&
            blogPosts.content &&
            blogPosts.content.length &&
            blogPosts.content.map((item, index) => (
              <div className="col-md-6" key={index}>
                <BlogPostCard2 data={item} />
              </div>
            ))}
        </div>
      )}

      {/* Pagination */}
      <div className="pagination-wrapper">
        <BlogListPagination
          baseURL={baseURL}
          currentPage={pageState.pageNo + 1}
          totalCount={pageState.totalItems}
          pageSize={pageState.pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

BlogPostListWithPagination.propTypes = {
  baseURL: PropTypes.string,
  isList: PropTypes.bool,
};

export default BlogPostListWithPagination;
