import { SocialLinks } from "components/utils/Social";
import { dataSocialLinks } from "data";
import SidebarBox from "./SidebarBox";

const msgTitle = "Мы в соцсетях";

/**
 * Component for showing Component "Sidebar box Social" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogBoxSocial = () => {
  return (
    <SidebarBox title={msgTitle} cn="social-box mb-3">
      <SocialLinks data={dataSocialLinks} />
    </SidebarBox>
  );
};

export default BlogBoxSocial;
