import {
  InputLabeledEmail,
  InputLabeledMessage,
  InputLabeledPhone,
  InputLabeledUsername,
} from "components/utils/Forms/Input";
import { dataForm } from "data";
//import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _debug } from "utils";

// NOTE : https://themes.getbootstrap.com/preview/?theme_id=50603

const msgSubmitted = "Сообщение успешно отправлено";
const msgSubmit = "Отправить сообщение";

/**
 * Component for showing Form "Contacts" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const FormContact2 = (props) => {
  const {
    methods,
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    //e.preventDefault();
    _debug("Form submit: ", data);

    setError(null);
    try {
      //gaEventTracker("submitFromCountDown");

      const response = await props.onSubmit({
        email: data.email,
      });

      if (response && response.error) {
        setError(response.error);
        setSuccess(false);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    } finally {
      reset();
    }
  };

  //   <form className="row g-4 needs-validation" novalidate="">
  //   <div className="col-sm-6">
  //     <label className="form-label fs-base" for="name">Name</label>
  //     <input className="form-control form-control-lg" type="text" placeholder="Your name" required="" id="name" wfd-id="id19">
  //     <div className="invalid-feedback">Please enter your name!</div>
  //   </div>
  //   <div className="col-sm-6">
  //     <label className="form-label fs-base" for="email">Email</label>
  //     <input className="form-control form-control-lg" type="email" placeholder="Email address" required="" id="email" wfd-id="id20">
  //     <div className="invalid-feedback">Please provide a valid email address!</div>
  //   </div>
  //   <div className="col-sm-6">
  //     <label className="form-label fs-base" for="phone">Phone</label>
  //     <input className="form-control form-control-lg" type="text" placeholder="Phone number" id="phone" wfd-id="id21">
  //   </div>
  //   <div className="col-sm-6">
  //     <label className="form-label fs-base" for="location">Location</label>
  //     <select className="form-select form-select-lg" id="location">
  //       <option value="All locations">All locations</option>
  //       <option value="Asia and Pacific">Asia and Pacific</option>
  //       <option value="Central Europe">Central Europe</option>
  //       <option value="Eastern Europe">Eastern Europe</option>
  //       <option value="North America">North America</option>
  //       <option value="South America">South America</option>
  //     </select>
  //   </div>
  //   <div className="col-sm-12">
  //     <label className="form-label fs-base" for="message">How can we help?</label>
  //     <textarea className="form-control form-control-lg" rows="5" placeholder="Enter your message here..." required="" id="message"></textarea>
  //     <div className="invalid-feedback">Please enter your message!</div>
  //   </div>
  //   <div className="col-sm-12">
  //     <div className="form-check form-check-inline">
  //       <input className="form-check-input" type="checkbox" id="agree" wfd-id="id22">
  //       <label className="form-check-label" for="agree">I agree to the <a className="nav-link d-inline fs-normal text-decoration-underline p-0" href="#">Terms &amp; Conditions</a></label>
  //     </div>
  //   </div>
  //   <div className="col-sm-12 pt-2">
  //     <button className="btn btn-lg btn-primary" type="submit">Send a request</button>
  //   </div>
  // </form>

  return (
    <div className="contacts-form-wrapper">
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
          method="POST"
          className="row g-4 needs-validation contacts-form"
        >
          <Form.Group controlId="formContactsName" as={Col} sm="12">
            <InputLabeledUsername
              label={dataForm.name.msgLabel}
              placeholder={dataForm.name.msgPlaceholder}
              required={dataForm.name.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContactsEmail" as={Col} mb="6">
            <InputLabeledEmail
              label={dataForm.email.msgLabel}
              placeholder={dataForm.email.msgPlaceholder}
              required={dataForm.email.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContactsPhone" as={Col} mb="6">
            <InputLabeledPhone
              label={dataForm.phone.msgLabel}
              placeholder={dataForm.phone.msgPlaceholder}
              required={dataForm.phone.msgRequired}
              register={register}
              control={control}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContactsMessage" as={Col} sm="12">
            <InputLabeledMessage
              label={dataForm.message.msgLabel}
              placeholder={dataForm.message.msgPlaceholder}
              required={dataForm.message.msgRequired}
              register={register}
              errors={errors}
            />
          </Form.Group>
          <Form.Group controlId="formContacts" as={Col} sm="12">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="agree" />
              <label className="form-check-label" htmlFor="agree">
                Вы принимаете условия{" "}
                <Link
                  className="nav-link d-inline fs-normal text-decoration-underline p-0"
                  to="/policies/terms"
                >
                  Политики &amp; Условий
                </Link>
              </label>
            </div>
          </Form.Group>
          <div className="col-sm-12 pt-2 text-center">
            <button type="submit" className="btn btn-lg btn-primary">
              {msgSubmit}
            </button>
          </div>
        </Form>
        {error && (
          <p className="text-warning" style={{ textAlign: "center" }}>
            <small>{error}</small>
          </p>
        )}
        {success && (
          <p className="text-success" style={{ textAlign: "center" }}>
            <small>{msgSubmitted}</small>
          </p>
        )}
      </FormProvider>
    </div>
  );
};

export default FormContact2;
