import classNames from "classnames";
import { DOTS, usePagination } from "hooks";
import { Fragment } from "react";
import { Pagination } from "react-bootstrap";

/**
 * Component for component "Blog List Pagination" of the site.
 *
 * @component
 */

const BlogListPagination = (props) => {
  const {
    baseURL,
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  // console.log(props);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // console.log("BASE URL: ", baseURL);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex - 1);
  };

  const onPrevious = () => {
    const pageIndex = currentPage - 1;
    onPageChange(pageIndex + 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  // Old function to delete: 16/12/2024
  const renderPaginationItems_old = () => {
    let content = [];
    for (let index = 0; index < totalCount; index++) {
      //console.log(index);
      //const item = animals[i];
      content.push(
        <Pagination.Item
          onClick={() => onPageChange(index)}
          key={index}
          active={index === currentPage - 1}
        >
          {index + 1}
        </Pagination.Item>
      );
    }
    return content;
  };

  const pagedURL = (baseURL, pageNumber) => {
    return `${baseURL}/page/${pageNumber}`;
  };

  const renderPaginationItems = () => {
    return (
      <Fragment>
        {paginationRange.map((pageNumber, index) => (
          <Fragment key={index}>
            {pageNumber === DOTS ? (
              <Pagination.Ellipsis key={index} className="dots">
                &#8230;
              </Pagination.Ellipsis>
            ) : (
              <Pagination.Item
                key={index}
                href={pageNumber > 1 ? pagedURL(baseURL, pageNumber) : baseURL}
                className={classNames(
                  "",
                  {
                    selected: pageNumber === currentPage,
                  },
                  {
                    active: pageNumber === currentPage,
                  }
                )}
                onClick={() => onPageChange(index)}
              >
                {pageNumber}
              </Pagination.Item>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <Pagination className="d-flex justify-content-center align-items-center">
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => onPageChange(0)}
      />
      <Pagination.Prev disabled={currentPage === 1} onClick={() => onNext()} />
      {renderPaginationItems()}
      <Pagination.Next
        disabled={currentPage === lastPage}
        onClick={() => onPrevious()}
      />
      <Pagination.Last
        disabled={currentPage === lastPage}
        onClick={() => onPageChange(lastPage - 1)}
      />
    </Pagination>
  );
};

export default BlogListPagination;
