import { SectionSubscribeLazy } from "components/sections";
import { Banner2 } from "components/sections/Banner";
import { SEO } from "components/utils/Analytics";
import { PageMain } from "core/components/Section";
import { dataSearchPage, dataSubscribe } from "data";
import { Fragment, useState } from "react";
import { SearchSection } from "./components";
import SectionSearchResult from "./components/SearchResult.section";

/**
 * Component for showing Page "Search" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageSearch = (props) => {
  const { id = "search" } = props;
  const [searchField2, setSearchField2] = useState("");

  const handleSearch = (search) => {
    setSearchField2(search);
  };

  return (
    <Fragment>
      <SEO data={dataSearchPage.meta}>
        {/**
         * Don’t canonicalize to a redirected page or a 404 page
         **/}
        <link rel="canonical" href="https://psywealth.com/search" />
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>*/}
      </SEO>
      <PageMain id={id}>
        <Banner2 data={dataSearchPage} />
        <SearchSection data={dataSubscribe} onSearchChange={handleSearch} />
        <SectionSearchResult data={dataSubscribe} searchField={searchField2} />
        {/* <CallToAction data={dataCTALetsContact} type="style2" /> */}
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
      </PageMain>
    </Fragment>
  );
};

export default PageSearch;
