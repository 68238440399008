import { BLOG_API_URI } from "config/config";
import { useFetch } from "hooks";
import { Fragment, useState } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
//import { logger } from "utils";
import { Spinner } from "components/utils/Spinner";
import { BLOG_ID } from "config/config";
import { PostListItem2 } from "./components/BlogPostCard3";
import ListPagination from "./components/ListPagination";

let PageSize = 3;

/**
 * Component for showing "Blog list of posts by Tag page" of the site.
 *
 * @component
 */
const PostsByTagPage = (props) => {
  const { slug } = useParams();
  const URL = `${BLOG_API_URI}/posts/tag/${slug}?`;
  const {
    data: postPages,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
  } = useFetch(URL, {
    pageNo: 0,
    pageSize: 5,
    blog: BLOG_ID,
  });
  const { page, setPage } = useState(0);
  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
  });

  //console.log(postPages);

  //const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return blogPosts?.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    //logger.log(pageNumber);
    // setState((prev) => ({ ...prev, activePage: pageNumber }));
    updateParams({
      pageNo: pageNumber,
    });
  };

  if (isLoading || hasError) {
    return (
      <Fragment>
        {isLoading && <Spinner />}
        {hasError && <p>{errorMessage}</p>}
      </Fragment>
    );
  }

  if (!postPages) return null;

  if (!postPages.content || postPages.content.length === 0) {
    return <h2>No posts founds</h2>;
  }

  return (
    <div className="post-list-wrapper">
      <Row>
        {postPages &&
          postPages.content &&
          postPages.content.length &&
          postPages.content.map((item, index) => (
            <PostListItem2 data={item} key={index} />
          ))}
      </Row>
      <ListPagination pages={postPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default PostsByTagPage;
