import { BLOG_API_URI } from "config/config";
import { Link } from "react-router-dom";
import BlogPostButton from "./BlogPostButton";
import BlogPostImage from "./BlogPostImage";
import BlogPostMetadata from "./BlogPostMetadata";

const POST_URL = `${BLOG_API_URI}/posts`;

const BlogPostContent = (props) => {
  const { data } = props;
  // console.log(data);

  return (
    <div className="blog-content">
      <ul className="post-category unordered-list">
        <li>
          <Link to={`/blog/category/${data.categorySlug}`}>
            <span className="date-meta">{data.categoryName}</span>
          </Link>
        </li>
      </ul>

      <BlogPostMetadata data={data} />

      {/* Content */}
      <h4 itemProp="headline" className="item-title">
        <Link itemProp="url" to={`/blog/${data.slug}`} className="line-clamp-2">
          {data.title}
        </Link>
      </h4>

      {/* <time itemprop="datePublished" datetime="2019-04-22">
        22 апреля 2019
      </time> */}

      <p itemProp="description" className="item-description line-clamp-5">
        {data.description}
      </p>
      {/* Control */}
      <BlogPostButton data={data} />
    </div>
  );
};

/**
 * Component for showing Component "Blog post card" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostCard = (props) => {
  const { data, index, useAnimation = false } = props;

  // console.log(data);

  return (
    <div
      className="single-blog blog-item aos-animate"
      {...(useAnimation && {
        "data-aos": "fade-up",
        "data-aos-delay": 200,
      })}
    >
      <BlogPostImage data={data} />
      <BlogPostContent data={data} />
    </div>
  );
};

export default BlogPostCard;
