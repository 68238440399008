import { useQuery } from "@tanstack/react-query";
import BlogService from "services/blog.service";

export const useGetBlogPostsByTag = (params = {}, options = {}) => {
  // console.log("!", params);

  const { tag, ...shortParams } = params;

  console.log(tag, shortParams);

  return useQuery({
    queryFn: () => BlogService.getBlogPostsByTag(params),
    queryKey: [
      "blog_posts_by_tag",
      {
        tag: tag,
        pageNo: shortParams.pageNo ? shortParams.pageNo : shortParams,
      },
    ],
    enabled: typeof params !== typeof undefined,
  });
};
