import classNames from "classnames";
import { ProgressiveImg2 } from "components/utils/Image";
import { BLOG_API_URI } from "config/config";
import { Link } from "react-router-dom";
import BlogPostButton from "./BlogPostButton";
import BlogPostMetadata from "./BlogPostMetadata";

import placeholderSrc from "assets/images/hello-world.webp";

const POST_URL = `${BLOG_API_URI}/posts`;

const BlogPostContent2 = (props) => {
  const { data } = props;

  const CardImage = (props) => {
    const { data } = props;

    const getImageURL = (imageId) => {
      return `${POST_URL}/imagedb/${data.imageURL}`;
    };

    return (
      <ProgressiveImg2
        hightSrc={
          data.id
            ? getImageURL(data.id)
            : "https://www.bootdey.com/image/380x380/00FFFF/000000"
        }
        lowSrc={placeholderSrc}
        alt={data?.imageAlt || data.title}
        width="640"
        height="360"
        loading="lazy"
        className="img-fluid"
      />
    );
  };

  const CardLink = (props) => {
    const { slug, cn, children } = props;
    return (
      <Link className={classNames(cn, "w-100")} to={`/blog/${slug}`}>
        {children}
      </Link>
    );
  };

  return (
    <div className="rounded-5 card-bordered card-lift card">
      <CardLink slug={data.slug}>
        <div className="blog-image-wrap">
          <CardImage data={data} />
          {/* <img
                        src="/static/media/mentor-img-7.272729140e1fb31da253.jpg"
                        alt="mentor 1"
                        className="img-fluid w-100 rounded-4"
                      />*/}
        </div>
      </CardLink>

      <div className="blog-content">
        <ul className="post-category unordered-list">
          <li>
            <Link
              to={`/blog/category/${data.categorySlug}`}
              className="btn btn-outline-light fs-base"
            >
              {/* <span className="date-meta">{data.categoryName}</span>*/}
              {data.categoryName}
            </Link>
          </li>
        </ul>

        <BlogPostMetadata data={data} />

        {/* Content */}
        <h4 className="item-title">
          <Link to={`/blog/${data.slug}`} className="line-clamp-2">
            {data.title}
          </Link>
        </h4>

        <p className="item-description line-clamp-5">{data.description}</p>
        {/* Control */}
        <BlogPostButton data={data} />
      </div>
    </div>
  );
};

/**
 * Component for showing Component "Blog post card" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogPostCard2 = (props) => {
  const { data, index, useAnimation = false } = props;

  // console.log(data);

  return (
    <div className="single-blog blog-item">
      <BlogPostContent2 data={data} />
    </div>
  );
};

export default BlogPostCard2;
