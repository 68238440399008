import ProgressiveImg2 from "components/utils/Image/ProgressiveImg2";
import { BLOG_API_URI } from "config/config";
import DOMPurify from "isomorphic-dompurify";
import { BlogPostMetadata } from "./index";

import placeholderSrc from "assets/images/placeholders/placeholder_900x600.webp";
import BlogPostTags from "./BlogPostTags";

/**
 * Component for showing Component "Blog Single Posts" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const BlogSinglePost = (props) => {
  const { data: postData } = props;

  return (
    <div className="single-blog blog-style-2 blog_details_section">
      <div className="details_image">
        <div className="blog-img">
          <ProgressiveImg2
            hightSrc={`${BLOG_API_URI}/posts/imagedb/${postData.imageURL}`}
            lowSrc={placeholderSrc}
            alt={postData.imageAlt || "PsyWealth.com"} // {data?.imageAlt || data.title}
            loading="lazy"
            className="img-fluid"
          />
        </div>
      </div>

      <div className="blog-details-content">
        <h1 className="h2 my-4">{DOMPurify.sanitize(postData.title)}</h1>

        {/* Blog Metadata */}
        <BlogPostMetadata data={postData} />

        {/* Blog Content */}
        <div className="site-content blog-content">
          <div className="">
            <p className="fst-italic">{postData.description}</p>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(postData.content),
            }}
          ></div>
        </div>

        {/* Blog Tags */}
        <BlogPostTags data={postData} />
      </div>
    </div>
  );
};

export default BlogSinglePost;
