import { ModalFormHeroOrder } from "components/forms/HeroOrder";
import {
  SectionAboutUsLazy,
  SectionCallToActionLazy,
  SectionCitiesLazy,
  SectionDemo,
  SectionFAQLazy,
  SectionFeatureTabsLazy,
  SectionHero,
  SectionOverview,
  SectionProblemsLazy,
  SectionServices,
  SectionSubscribeLazy,
  SectionTopAdviceLazy,
  SectionTopCoursesLazy,
  SectionTopPostsLazy,
  SectionTopPsychologistsLazy,
  SectionWhatWeDoLazy,
} from "components/sections";
import { SEO } from "components/utils/Analytics";
import { isDevelopment } from "components/utils/Utils";
import { PageMain } from "core/components/Section";
import {
  dataAboutUsMainPage as dataAboutUs,
  dataAltFeatureMainPage,
  dataBlogMainPage as dataBlog,
  dataCitiesMainPage,
  dataCoursesPage,
  dataCTAMainPage,
  dataCTAMainPageExtra,
  dataDemoMainPage,
  dataFAQ,
  dataFeatureTabsMainPage,
  dataHeroMainPage as dataHero,
  dataHomePage,
  dataOverviewMainPage as dataOverview,
  dataSubscribe,
  dataTopAdviceMainPage as dataTopAdvice,
  dataTopPsychologistsMainPage as dataTopPsychologists,
  dataUsedForMainPage,
  dataWhatWeDoMainPage as dataWhatWeDo,
  ldJson,
} from "data";
import { useModal } from "hooks";
import React, { Fragment, useRef } from "react";
import { useLocation } from "react-router-dom";
import EventService from "services/events.service";
import { _debug } from "utils";

// NOTE :

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 * @version: 1.0.0
 **/
const PageHome = (props) => {
  const { id = "home" } = props;
  const location = useLocation();
  const isRootPage = location.pathname === "/";

  // console.log(location, isRootPage);

  const refCAT = useRef(null);

  const handlerHeroSubmit = async (data) => {
    _debug("Hero submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = {
        ...data,
        phone: data.phone.replace(numberRegex, ""),
      };
      console.log(newData);
      const result = await EventService.registerOrder(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    } finally {
      // closeModal1();
    }
  };

  const handlerCTASubmit = async (data) => {
    _debug("CTA submit form: ", data);
    try {
      var numberRegex = /\D/g;
      const newData = { ...data, phone: data.phone.replace(numberRegex, "") };
      console.log(newData);
      const result = await EventService.registerOrder(newData);
      console.log(result);
    } catch (err) {
      console.error(err);
    }
  };

  const {
    modal: modal1,
    openModal: openModal1,
    closeModal: closeModal1,
  } = useModal({
    children: (
      <ModalFormHeroOrder
        onSubmit={(data) => handlerHeroSubmit(data)}
        onClose={() => closeModal1()}
      />
    ),
  });

  const {
    modal: modal2,
    openModal: openModal2,
    closeModal: closeModal2,
  } = useModal({
    children: (
      <ModalFormHeroOrder
        onSubmit={(data) => handlerCTASubmit(data)}
        onClose={() => closeModal2()}
      />
    ),
  });

  const executeScroll = (e) => {
    refCAT.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <Fragment>
      <SEO data={dataHomePage.meta}>
        {/*Only canonicalize pages which are a duplicate or are near-identical.*/}
        {isRootPage ? (
          <link rel="canonical" href="https://psywealth.com" />
        ) : (
          <link rel="canonical" href="https://psywealth.com" />
        )}
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </SEO>
      <PageMain id={id}>
        <SectionHero
          data={dataHero}
          type="style4"
          onScroll={executeScroll}
          onSubmit={openModal1}
        />
        <SectionAboutUsLazy
          data={dataAboutUs}
          type={"style1"}
          showHeader={false}
        />
        <SectionProblemsLazy data={dataUsedForMainPage} type={"style2"} />
        <SectionWhatWeDoLazy
          data={dataWhatWeDo}
          type={"style2"}
          id={"what-we-do"}
        />
        <SectionOverview data={dataOverview} type={"style1"} />
        <SectionWhatWeDoLazy
          data={dataAltFeatureMainPage}
          type={"style1"}
          id={"what-we-do"}
        />
        <SectionFeatureTabsLazy
          data={dataFeatureTabsMainPage}
          type={"style1"}
        />
        <SectionCallToActionLazy
          data={dataCTAMainPageExtra}
          type={"style4"}
          id={"call-to-action"}
          onSubmit={() => openModal2()}
        />
        <SectionTopPsychologistsLazy
          data={dataTopPsychologists}
          cn=""
          type={"style2"}
        />
        {/*<TestimonialStyle2 data={dataAltFeatureMainPage} />*/}
        <SectionServices data={dataAltFeatureMainPage} type={"style1"} />
        {/* <OurAdvantages2 data={dataOurAdvantagesMainPage} />*/}
        <SectionCitiesLazy data={dataCitiesMainPage} type={"style1"} />
        <SectionCallToActionLazy
          data={dataCTAMainPage}
          type={"style2"}
          id={"call-to-action"}
          onSubmit={() => openModal2()}
        />
        <SectionTopAdviceLazy data={dataTopAdvice} type={"style2"} />
        <SectionTopPostsLazy data={dataBlog} type={"style2"} />
        {isDevelopment && (
          <SectionTopCoursesLazy data={dataCoursesPage} type={"style2"} />
        )}
        <SectionDemo data={dataDemoMainPage} type={"style1"} />
        <SectionFAQLazy data={dataFAQ} type={"style1"} />
        <SectionSubscribeLazy data={dataSubscribe} type="style3" />
        {modal1}
        {modal2}
      </PageMain>
    </Fragment>
  );
};

export default PageHome;
